import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import Button from "./Button";

function Card({ imagen}) {
  console.log(imagen)
  
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });
  return (
    <animated.div
      className={Styles.card}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <img src={imagen[0]} alt="" />
      <h3>{imagen[1]}</h3>
      <p>
        {imagen[2]}
      </p>
      <div className={Styles.btnn}>
        <a href={imagen[3]} target="_blank" rel="noreferrer"><Button text="Lien" /></a>
      </div>
    </animated.div>

  );
}

export default Card;