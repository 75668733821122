import "./Lang.css";
import "./Query.css";

const handleClick = () => {
  const switchClasses = document.querySelectorAll(".off, .on");
  const html = document.querySelectorAll(".langagesImg");

  switchClasses.forEach((switchClasse) => {
    if (switchClasse.classList.contains("off")) {
      switchClasse.classList.remove("off");
      switchClasse.classList.add("on");

      html.forEach((el) => {
        el.classList.remove("translateLangOFF");
        el.classList.add("translateLangON");
      });
    } else if (switchClasse.classList.contains("on")) {
      switchClasse.classList.remove("on");
      switchClasse.classList.add("off");

      html.forEach((el) => {
        el.classList.add("translateLangOFF");
        el.classList.remove("translateLangON");
      });
    }
  });
};

function Lang() {
  return (
    <div className="Lang">
      <div className="container">
        <div id="langages" className="separationLang"></div>

        <h2>Langages</h2>
        <div className="langagesBush">
          <p className="bushText" onClick={handleClick}>
            Clique moi pour <br></br>voir mes langages !
          </p>
          <img
            className="bush"
            src="img/bush.png"
            alt=""
            onClick={handleClick}
          ></img>
          <div className="boulesLangage">
            <img
              className="langagesImg off translateLangOFF"
              src="img/imgLangage/langagesImg.webp"
              alt=""
            ></img>
          </div>
        </div>
        <div className="langagesBushTel">
          <p className="telText">La barre de progression représente mon niveau d'aisance et de connaissances dans le langage.</p>
          <div className="htmlTel">
            <label for="file">
              {" "}
              <img className="" src="img/imgLangage/html.png" alt=""></img>
            </label>
            <progress id="file" max="100" value="100"></progress>
          </div>

          <div className="cssTel">
            {" "}
            <label for="file">
              {" "}
              <img className="" src="img/imgLangage/css.png" alt=""></img>
            </label>
            <progress id="file" max="100" value="90"></progress>
          </div>

          <div className="jsTel">
            {" "}
            <label for="file">
              {" "}
              <img className="" src="img/imgLangage/js.png" alt=""></img>
            </label>
            <progress id="file" max="100" value="65"></progress>
          </div>

          <div className="reactTel">
            {" "}
            <label for="file">
              {" "}
              <img className="" src="img/imgLangage/react.png" alt=""></img>
            </label>
            <progress id="file" max="100" value="75"></progress>
          </div>

          <div className="mysqlTel">
            {" "}
            <label for="file">
              {" "}
              <img className="" src="img/imgLangage/mysql.png" alt=""></img>
            </label>
            <progress id="file" max="100" value="70"></progress>
          </div>

          <div className="phpTel">
            {" "}
            <label for="file">
              {" "}
              <img className="" src="img/imgLangage/php.png" alt=""></img> (Langage pas encore étudier)
            </label>
            <progress id="file" max="100" value="0"></progress>
          </div>

          <div className="symfonyTel">
            {" "}
            <label for="file">
              <img className="" src="img/imgLangage/symfony.png" alt=""></img> (Langage pas encore étudier)
            </label>
            <progress id="file" max="100" value="0"></progress>
          </div>
        </div>
      </div>

      <div className="container">
        <div id="projets" className="separation"></div>
      </div>
    </div>
  );
}
export default Lang;
