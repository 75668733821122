import "./App.css";
import "./Query.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="container">
          <nav>
            <img src="img/logo.webp" alt=""></img>
            <div className="nav-holder">
              <div className="nav-button-focus">
                <img src="img/liane.webp" alt="" className="lianeBtn1"></img>
                <a href="#langages" className="nav-button">
                  Langages
                </a>
              </div>
              <div className="nav-button-focus">
                <img src="img/liane.webp" alt="" className="lianeBtn2"></img>
                <a href="#projets" className="nav-button">
                  Projets
                </a>
              </div>
              <img
                src="img/lianeTombantes.webp"
                alt=""
                className="lianeTombante"
              ></img>
            </div>
          </nav>
          <div className="line"></div>

          <div className="presentation">
            <h1>
              Développeur Web <br></br> Web Mobile
            </h1>
          </div>
          <div className="line"></div>
          
        </div>

        <div className="container centerPresentation">
          <div className="rectangleG"></div>
            <div className="rectangleP">
              <p className="txtPresentation">
                Salut, moi c'est Alex un jeune Développeur Web. Vous êtes sur
                mon portfolio alors découvrez mes compétences !
              </p>
            </div>
          
          <img className="imgMoi" src="img/moi.webp" alt=""></img>
        </div>
      </header>
    </div>
  );
}

export default App;
