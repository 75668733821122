import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Lang from "./Lang";
import Footer from "./Footer";
import Loader from "./Loader";
// import PopUp from "./PopUp";
import AppCarouse from "./AppCarousel";


function MainApp() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      setTimeout(() => {
          setLoading(false);
      }, 3000);
  }, []);

  if (loading) {
      return <Loader />;
  }

  return ( 

      <React.StrictMode>
        {/* <PopUp /> */}
          <App />
              <Lang />
            <AppCarouse />
          <Footer />
      </React.StrictMode> 

  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<MainApp />);

reportWebVitals();