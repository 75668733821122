import "./Loader.css";

function Loader() {
  return (
    <div className="centerLoader">
    <div className="🤚">
      <div className="👉"></div>
      <div className="👉"></div>
      <div className="👉"></div>
      <div className="👉"></div>
      <div className="🌴"></div>
      <div className="👍"></div>
    </div>
    </div>
  );
}

export default Loader;
