import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";

function AppCarousel() {
  let cards = [
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/axit-img.webp",
            "axit",
            "Un site réalisé en cours de formation exclusivement en HTML et CSS à partir d'une maquette Figma.",
            "https://axit.alexper.fr",
          ]}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/photographe-img.webp",
            "robbie lens",
            "Un site réalisé au cours d'un cours en ligne sur Openclassrooms en HTML et CSS.",
            "https://robbielens.alexper.fr",
          ]}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/jane-doe.webp",
            "jane.doe",
            "Un site réalisé lors d'une évaluation sur WordPress, il a été fait avec les blocs thèmes WordPress.",
            "https://janedoe.alexper.fr",
          ]}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/lorem-cafe.webp",
            "lorem cafe",
            "Un site réalisé au cours de la partie WordPress pour apprendre le SIO et les méthodes de référencement des sites.",
            "https://loremcafe.alexper.fr",
          ]}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/nouv-portfolio.webp",
            "funny portfolio",
            "Un site `Portfolio` réalisé pour un entrainement sur les blocs thèmes WordPress",
            "https://wpportfolio.alexper.fr",
          ]}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/owm-api.webp",
            "owm api",
            "Un site réalisé au cours de la partie JavaScript pour apprendre les appels d'API et donc celle ci qui est l'API de Open Weather Map.",
            "https://owmapi.alexper.fr",
          ]}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <Card
          imagen={[
            "img/imgSite/tmdb-api.webp",
            "tmdb api",
            "Un site réalisé au cours de la partie JavaScript et ensuite refait avec React pour avoir ce résultat la pour apprendre les appels d'API avec React.",
            "https://tmdbapi.alexper.fr",
          ]}
        />
      ),
    },
  ];
  return (
    <>
    <div className="container">
      <h2>Projets</h2>
    </div>
    <div className="">
      <Carousel
        cards={cards}
        height="500px"
        width="40%"
        margin="0 auto"
        offset={3}
        showArrows={false}
      />
    </div>
    </>
  );
}

export default AppCarousel;
